import React from 'react'
import styles from './Home.module.css';
import { Link } from 'react-scroll';


const Home = () => {
  return (
  <div name="Home" className={styles.home}>
    <div ></div>
    <div className={styles.titleContainer}>
    <p>
    <img className={styles.webImage}src={require('../assets/kometa2.jpeg')}/> <br />
    <b>Kometa 3 es un estudio de Diseño y Producción Gráfica dedicado a la fabricación de letreros diversos, Anuncios luminosos, Placas, Tótems Impresión de Gráficos, Rotulación Digital, Rotulación vehicular, Señalética, Stands y escenografias entre otros </b>
    
    </p>
    <div className={styles.titleContainer2}>
     <p>
      <b>"Con nuestra creatividad y las ideas de nuestros clientes, expresamos o comunicamos mensajes a través de formas, palabras o imágenes impresas"</b>
      </p> 
    </div>
    </div>

  </div>
  );
};

export default Home