import React from 'react'
import styles from './Benefits.module.css';
import { BsFillDoorOpenFill } from "react-icons/bs";
import { FaPeopleCarry } from "react-icons/fa";
import { FaMoneyBillAlt } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import { AiOutlineRise } from "react-icons/ai";
import { FiRepeat } from "react-icons/fi";
import { BsCheckCircleFill } from "react-icons/bs";

const Benefits = () => {
  return (
    <div name="Benefits" className={styles.benefits}>
      <h2 className={styles.benefitTitle}>
        Beneficios
      </h2>
      <p>
        Abierto a todo el publico <BsFillDoorOpenFill />
      </p>

      <p>
        Materiales de buena calidad <BsCheckCircleFill  />
      </p>

      <p>
        El mejor Servicio <AiOutlineSchedule />
      </p>
      <p>
        Mayor comunicación <FiRepeat />
      </p>
    </div>
  )
}

export default Benefits