import React from 'react'
import styles from './Services.module.css'

const Services = () => {
  return (
  <div name="Services" className={styles.services}>
    <p>Conoce nuestros productos</p>
    <h1>Logotipos con Volumen</h1>
    <img className={styles.webImage}src={require('../assets/titulo.jpg')}/>
    <img className={styles.webImage}src={require('../assets/titulo2.jpg')}/>
    <img className={styles.webImage}src={require('../assets/titulo3.JPG')}/>
    <img className={styles.webImage}src={require('../assets/titulo4.jpg')}/>

    <h1>Logos Luminosos</h1>
    <img className={styles.webImage}src={require('../assets/logolum.jpg')}/>
    <img className={styles.webImage}src={require('../assets/logolum2.JPG')}/>
    <img className={styles.webImage}src={require('../assets/logolum3.jpg')}/>
    <img className={styles.webImage}src={require('../assets/logolum4.JPG')}/>

    <h1>Luminosos Neon y Led</h1>
    <img className={styles.webImage}src={require('../assets/neon.jpg')}/>
    <img className={styles.webImage}src={require('../assets/neon6.jpg')}/>
    <img className={styles.webImage}src={require('../assets/neon7.jpg')}/>
    <img className={styles.webImage}src={require('../assets/neon4.jpg')}/>

    <h1>Letreros Luminosos</h1>
    <img className={styles.webImage}src={require('../assets/letrerolum.jpg')}/>
    <img className={styles.webImage}src={require('../assets/letrerolum2.jpg')}/>
    <img className={styles.webImage}src={require('../assets/letrerolum5.jpg')}/>
    <img className={styles.webImage}src={require('../assets/letrerolum4.jpg')}/>
    
    <h1>Stands Portatiles</h1>
    <img className={styles.webImage}src={require('../assets/stand5.png')}/>
    
    <img className={styles.webImage}src={require('../assets/stand3.jpg')}/>
   

    <h1>Stand para Eventos</h1>
    <img className={styles.webImage}src={require('../assets/standex.jpg')}/>
    <img className={styles.webImage}src={require('../assets/standex2.jpg')}/>
    <img className={styles.webImage}src={require('../assets/standex3.jpg')}/>
    <img className={styles.webImage}src={require('../assets/standex4.jpg')}/>

    <h1>Señalamientos</h1>
    <img className={styles.webImage}src={require('../assets/señal.jpg')}/>
    <img className={styles.webImage}src={require('../assets/señal5.jpg')}/>
    <img className={styles.webImage}src={require('../assets/señal3.jpg')}/>
    <img className={styles.webImage}src={require('../assets/señal6.jpg')}/>
    
    <h1>Rotulación Digital</h1>
    <img className={styles.webImage}src={require('../assets/rotulacion.jpg')}/>
    <img className={styles.webImage}src={require('../assets/rotulacion2.jpg')}/>
    <img className={styles.webImage}src={require('../assets/rotulacion3.jpg')}/>
    <img className={styles.webImage}src={require('../assets/rotulacion4.jpg')}/>

    <h1>Rotulación en vehiculos</h1>
    <img className={styles.webImage}src={require('../assets/rotve.JPG')}/>
    <img className={styles.webImage}src={require('../assets/rotve2.jpg')}/>
    <img className={styles.webImage}src={require('../assets/rotve3.JPG')}/>
    <img className={styles.webImage}src={require('../assets/rotve4.JPG')}/>
    
  </div>
  
  );
}

export default Services