import React, { useEffect, useState } from 'react'
import { Link } from "react-scroll";
import styles from "./NavBar.module.css"
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { useScrollPosition } from './ScrollPosition';


const NavBar = () => {
    const[NavBarOpen, setNavBarOpen] = useState(false)
    const[windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const detectDimension = () =>{
        setWindowDimension({
            width: window.innerWidth,
        height: window.innerHeight
        })
    }

    useEffect(()=>{
        window.addEventListener('resize', detectDimension)
        windowDimension.width > 800 && setNavBarOpen(false)
        return () =>{
            window.removeEventListener('resize', detectDimension)
        }
    },[windowDimension])

        const links=[
            {
                id: 1,
                link: "Inicio",
            },
            {
                id: 2,
                link: "Servicios",
            },
            {
                id: 3,
                link: "Como trabajamos?"
            },
            {
                id:4,
                link: "Beneficios"
            },
        ]
        const scrollPosition = useScrollPosition ();
  return (
    <div
     className={
        NavBarOpen 
        ? styles. navOpen
        : scrollPosition > 0
        ? styles. navOnScroll
        : styles.navBar
     
     }>


       {!NavBarOpen && <p className={styles.logo}>KOMETA3 STUDIO</p> }
       {!NavBarOpen && windowDimension.width < 800?(
        <AiOutlineMenu onClick={() => setNavBarOpen(!NavBarOpen)} size={30}/>
       ): windowDimension.width < 800 &&( 
       <AiOutlineClose onClick={() => setNavBarOpen(!NavBarOpen)}size={30}/>
        )}

       {NavBarOpen  && (
        <ul className={styles.linksContainer}>
        {links.map((x) =>(
            <div>
                <Link
                onClick={() => setNavBarOpen(false)}
                to={x.link}
                smooth
                duration={500}
                className={styles.navLink}
                >{x.link === "HowWeWork" ? "How We Work" : x.link}</Link>
                <div className={styles.border}></div>
            </div>
        ))}
        </ul>
       )}
       {
        windowDimension.width >800 && (
            <ul className={styles.linksContainer}>
        {links.map((x) =>(
            <div>

                <div className={styles.border}></div>
            </div>
        ))}
        <Link 
        onClick={() => setNavBarOpen(false)}
                to='Home'
                smooth
                duration={500}
                className={styles.ContactLink}>Inicio</Link>
                       <Link 
        onClick={() => setNavBarOpen(false)}
                to='Services'
                smooth
                duration={500}
                className={styles.ContactLink}>Servicios</Link>
                       <Link 
        onClick={() => setNavBarOpen(false)}
                to='HowWeWork'
                smooth
                duration={500}
                className={styles.ContactLink}>Como trabajamos?</Link>
                                  <Link 
        onClick={() => setNavBarOpen(false)}
                to='Benefits'
                smooth
                duration={500}
                className={styles.ContactLink}>Beneficios</Link>
                       <Link 
        onClick={() => setNavBarOpen(false)}
                to='Contact'
                smooth
                duration={500}
                className={styles.ContactLink}>Contacto</Link>
                
        </ul>
        )}

        
     </div>
  )
}

export default NavBar;