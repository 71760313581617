import React from "react";
import styles from "./Contact.module.css";
import { Link } from "react-scroll";
import { IoLogoFacebook } from "react-icons/io";
import { SiMicrosoftoutlook } from "react-icons/si";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
const Contact = () => {
  return (
    <div name="Contact" className={styles.contact}>
      <h2>Red Social y contactos</h2>

      <a className={styles.as} href="https://www.facebook.com/kometa3studio/"><FaArrowRight /> Facebook <IoLogoFacebook /> <FaArrowLeft /></a> 
      <a className={styles.asi} href="https://www.google.com/maps/place/20%C2%B059'48.1%22N+89%C2%B037'19.5%22W/@20.9967008,-89.6246647,17z/data=!3m1!4b1!4m4!3m3!8m2!3d20.9967008!4d-89.6220898?hl=es&entry=ttu"><FaArrowRight /> Maps <FaMapMarkerAlt /> <FaArrowLeft /></a>

        <Link
          smooth
          duration={500}
          className={styles.callToAction2}
        >
          
          kometa3studio@outlook.com <SiMicrosoftoutlook />
        </Link>

        <Link
          smooth
          duration={500}
          className={styles.callToAction3}
        >
          
          Telefono: 999 920 0920 <BsFillTelephoneFill />
        </Link>
    </div>
  );
};

export default Contact;
