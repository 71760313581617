import React from 'react'
import styles from './HowWeWork.module.css';
import Step from '../Components/Step';


const HowWeWork = () => {
  const steps = [
    {
      text: " Contactanos atravez del boton de Whatsapp.",
      id: 1,
    },
    {
      text: " Platicanos que tienes en mente.",
      id: 2,
    },
    {
      text: " Lo cotizamos.",
      id: 3,
    },
    {
      text: " Lo creamos.",
      id: 4,
    },
    {
      text: " Y te lo entregamos!",
      id: 5,
    },
  ];


  return (<div name="HowWeWork" className={styles.howWeWork}>
    <h2 className={styles.title}>¿Como trabajamos?</h2>
    {
      steps.map(x =>(
        <Step text={x.text} step={x.id}/>
      ))
    }
  </div>);
};

export default HowWeWork